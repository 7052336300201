<template>
  <div>
    <section>
      <hero-global title="Gobernanza CERMI" first-width="78" last-width="22" img="/img/how-we-organize/how-we-organize_1.svg" />

    </section>
    <div class="container p-1-rem">
      <section class="menu-anclas container">
        {{this.$route?.params?.slug}}
        <router-link :class="[this.$route?.params?.slug === 'organigrama' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']" :to="{ name:'asi-nos-organizamos', params:{ slug:'organigrama' } }" @click="scrollSection('organigrama')">Organigrama</router-link>
        <router-link :to="{ name:'asi-nos-organizamos', params:{ slug:'organos-gobierno' } }" @click="scrollSection('organos-gobierno')" :class="[this.$route?.params?.slug === 'organos-gobierno' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">Órganos de Gobierno</router-link>
        <router-link :to="{ name:'asi-nos-organizamos', params:{ slug:'equipo-humano' } }" @click="scrollSection('equipo-humano')" :class="[this.$route?.params?.slug === 'equipo-humano' ? 'btn btn-tertiary active-class': 'btn btn-tertiary ']">Equipo Humano</router-link>
      </section>
      <div class="container">
        <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive" v-model="menuAnclas" :activeMenuTop="menuTopAnclas">
          <option value="organigrama">
            Organigrama
          </option>
          <option value="organos-gobierno">
            Órganos de Gobierno
          </option>
          <option value="equipo-humano">
            Equipo Humano
          </option>

        </select>
      </div>

      <section class="p-2-rem" id="organigrama">
        <h2 class="title-grand title__double-center" data-text="Organigrama">Organigrama</h2>
        <br />
        <br />
        <div class="grid-2">
          <div class="grid p-2-rem p-top-5-rem">
            <h3 class="subtitle font-size-30">Organigrama CERMI Estatal</h3>
            <div class="w-80">
              <p class="font-light">Los cargos en orden descendente son la Asamblea de Representantes, Comité Ejecutivo,
                Comisión Permanente, Presidencia, las Vicepresidencias y Secretaria General, colgando al mismo nivel se
                encuentran las Personas Comisionadas, Delegaciones y Asesorías ; Estructuras de Apoyo: Comisiones de
                Trabajo, Grupos de Trabajo y Redes.</p>
            </div>
          </div>
          <div class="grid">
            <img class="d-block w-100" src="/img/how-we-organize/how-we-organize_2.svg" title="Organigrama CERMI Estatal" alt="Organigrama CERMI Estatal" />
          </div>
          <br />
          <br />
          <br />
          <div class="grid">
            <img class="d-block w-100" src="/img/how-we-organize/how-we-organize_3.png" title="Organigrama Unipersonal" alt="Organigrama Unipersonal CERMI" />
          </div>
          <div class="grid p-3-rem">
            <h3 class="subtitle font-size-30">Organigrama Unipersonal</h3>
            <p class="font-light">En el segundo cuadro, Organigrama de Cargos Unipersonales del CERMI, los cargos en orden descendente son
              Presidencia, Vicepresidencias, Secretaria General, de quienes dependen la Dirección de Comunicación,
              Asesoría Jurídica, el Comité de Igualdad y la Dirección Ejecutiva. De la Dirección Ejecutiva dependen la
              Gerencia y Delegación de la CDPD y de derechos humanos. De la Gerencia dependen la Jefatura de Secretaria,
              Adjunta a la Gerencia- Personal de Apoyo, Gestión económico - financiera y Gestora de CERMI Digital.</p>
          </div>
        </div>
      </section>
      <section class="p-2-rem" id="organos-gobierno">
        <div v-if="store.goverments">
          <h2 class="title-grand title__double-center" data-text="Órganos de Gobierno">Gobierno</h2>
          <div class="accordion box-content">
            <div class="content w-100">

              <ul class="list-item list">
                <li class="m-10-px" v-for="(goverment, key) in store.goverments" :key="key" :aria-expanded="this.currentShow == goverment.dataId">
                  <input type="checkbox" name="list" :id="goverment.dataId"  @click="toggle(goverment.dataId)" />
                  <label :for="goverment.dataId">
                    <span class="title">{{goverment.name}}</span>
                  </label>
                  <div class="inner-container" :show="this.currentShow == goverment.dataId">
                    <hr class="hr color-black" />
                    <div class="grid-3 members" v-if="Object.keys(goverment.employees).length > 0">

                      <router-link :to="{
                          name: 'organos-de-gobierno',
                          params: {
                            id: employe.employee.slug,
                            idDeparment : goverment.slug
                          },
                        } " class="grid" v-for="(employe, key) in goverment.employees" :key="key">
                        <p><strong>{{employe.employee.name}}</strong></p>
                        <p>{{employe.position}}</p>
                      </router-link>
                    </div>
                    <template v-else>
                      <div class="grid m-top-20-px">
                        <p class="subtitle">No hay miembros</p>
                      </div>
                    </template>
                  </div>
                </li>

              </ul>
            </div>
          </div>

        </div>
      </section>
      <section id="equipo-humano">
        <div class="column-center">
          <h2 class="title-grand title__double-center" data-text="CERMI Estatal">Equipo Humano </h2>
          <div class="info-e-h">
            <p class="subtitle text-center">Datos Básicos</p>
            <p class="description">La plantilla laboral del CERMI está formada por 10 personas.<br>
             Todas con contrato laboral indefinido y a tiempo completo, menos 1 de interinidad a tiempo completo y otro temporal de Fomento de Empleo de Personas con Discapacidad.</p>
            <div class="grid-2">
              <div class="grid">
                <img src="/img/datos_basico_1.jpg" alt="Imagen de datos básicos de comó nos organizamos 1">
              </div>
              <div class="grid">
                <img src="/img/datos_basico_2.jpg" alt="Imagen de datos básicos de comó nos organizamos 2">
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-inline-block w-30">
            <img title="Equipo Humano" alt="Equipo Humano" class="d-block w-100" src="/img/how-we-organize/how-we-organize_5.png" />
          </div>
          <div class="background-blue-3 d-inline-block w-70 p-5-rem h-100">
            <p>Las personas integrantes de los órganos de gobierno y representación del CERMI no perciben ninguna remuneración por el desempeño de sus responsabilidades sociales.</p>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section>
        <carrousel2 />
      </section>
      <br />
      <other-links-interest class="links-interest-box m-y-4-rem" />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { contentStore } from '@/stores/contents'
  export default {
    name: "HowOrganize",
    components: { Carrousel2, OtherLinksInterest, HeroGlobal },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.setSection()
      this.store.loadGoverments()
      this.topMenuAnclas()
    },
    data() {
      return {
        menuAnclas : 'organigrama',
        menuTopAnclas: false,
        currentShow: null,
      }
    },
    methods: {
      setSection() {
         this.menuAnclas =  this.$route?.params?.slug ? this.$route?.params?.slug : 'organigrama'
        if (this.$route?.params?.slug) {
          let element = this.$route?.params?.slug.replace('#', '')
          element !== null && (() => {
            const offsetTop = document.getElementById(element).offsetTop - 150;
            setTimeout(function () {
              scroll({
                behavior: "smooth",
                top: offsetTop,
              });
            }, 200);
          })()
        }
        else {
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: 0,
            });
          }, 200);
        }
      },
      topMenuAnclas: function () {
        window.onscroll = () => {
          if (window.scrollY > 0) {
            this.menuTopAnclas = true;
          } else {
            this.menuTopAnclas = false
          }
        }
      },
      toggle(id) {
        if (this.currentShow === id) {
          this.currentShow = null;
        } else {
          this.currentShow = id;
        }
    }
    },
    computed: {
     
    },
    watch: {
      "$route"() {
        this.setSection()
      },
      "menuAnclas"() {
        this.$router.push({
          params: {
            name: 'asi-nos-organizamos',
            slug: this.menuAnclas,
          }
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/colours";

  .box-content {
    background: transparent;

    .list-item.list>li {
      border-bottom: 1px solid $blueLight;
    }

    .inner-container {
      padding-left: 0 !important;

      ul {
        li {
          border-bottom: none;
        }
      }
    }

    li {
      padding: 0 !important;
    }

    label::before,
    .item-content.-one::before {
      bottom: 10px;
    }
  }

  .hr {
    border-top: 2px solid white;
    border-bottom: none;
    border-radius: 3px;

    &.color-black {
      border-top: 1px solid black !important;
    }
  }

  .members {
    .grid {
      margin: 10px 0;
      padding: 0 20px;
      text-decoration: none;
      color: black;

      &:hover {
        text-decoration: none;
        color: black;
      }

      p {
        margin-bottom: 5px;
        font-family: 'Frank Ruhl Libre', serif;
        font-size: 18px;

        strong {
          font-size: 20px;
        }
      }
    }

  }

  .info-e-h {
    .subtitle {
      font-size: 28px;
    }

    .description {
      max-width: 750px;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding: 25px 0;
    }

    .grid-2 {
      max-width: 1000px;
      margin-bottom: 60px;

      .grid {
        &:first-of-type {
          width: 55%;
        }

        &:last-of-type {
          width: 43%;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 895px) {

    .info-e-h .grid-2 .grid:first-of-type,
    .info-e-h .grid-2 .grid:last-of-type {
      width: 100%;
    }
  }
</style>